import React, { useState, useEffect, useLayoutEffect } from "react";
import { StyleSheet, View, FlatList } from "react-native";
import { Button, TextInput, Text, useTheme, Divider, List } from "react-native-paper";
import { useAppSelector } from "../hooks";
import { useNavigation } from "@react-navigation/native";
import { useFetchFanResponsesMutation } from "../api/fanResponses";

const dashboardPassword = "test"

const CreatorDashboardScreen = () => {
  const navigation = useNavigation();
  const theme = useTheme();
  const clientId = useAppSelector((state) => state.user.clientId);

  const [creatorId, setCreatorId] = useState("");
  const [messages, setMessages] = useState<any[]>([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [page, setPage] = useState(1);
  const [isAuthenticated, setAuth] = useState(false);

  const [fetchFanResponses, { isLoading, data, error }] = useFetchFanResponsesMutation();

  useLayoutEffect(() => {
    navigation.setOptions({
      title: "Creator Dashboard",
      headerStyle: {
        height: 81,
      },
      headerTintColor: theme.colors.onSurfaceVariant,
    });
  }, [navigation]);

  const fetchMessages = (pageNumber: number) => {
    setErrorMessage("");
    fetchFanResponses({ 
      clientId,
      fanResponseReq: { creatorId, page: pageNumber }, 
    });
  };

  const fetchNextMessages = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    fetchMessages(nextPage);
  };

  const fetchPrevMessages = () => {
    const prevPage = page - 1;
    setPage(prevPage);
    fetchMessages(prevPage);
  };

  const promptAuth = () => {
    const password = prompt("Please enter the password");

    if (password !== dashboardPassword) {
      return false;
    } else {
      setAuth(true); 
      return true;
    }
  }

  useEffect(() => {
    if (!isAuthenticated && !promptAuth()) return; 
  }, [isAuthenticated])
  
  useEffect(() => {
    if (data) {
      setMessages(data.fanMessages);
    }
    if (error) {
      if (error instanceof Error) {
        setErrorMessage(error.message);
        // setErrorMessage("Error: only admin can access this page");
      } else {
        setErrorMessage("An unknown error occured");
      }
    }
  }, [data, error]);

  return (
    <View style={styles.container}> 
      { 
        !isAuthenticated ? 
          <Button
            mode="contained"
            style={styles.formButton}
            disabled={isAuthenticated}
            onPress={promptAuth}
            > 
            Authenticate
          </Button> 
        : 
          <View>
            <TextInput
              label="Creator ID"
              value={creatorId}
              onChangeText={(text) => setCreatorId(text)}
              style={styles.formField}
            />
            <Button
              mode="contained"
              style={styles.formButton}
              disabled={!creatorId}
              onPress={() => fetchMessages(page)}
              loading={isLoading} // Use isLoading from RTK Query
            >
              Fetch Fan Messages
            </Button>
            {errorMessage ? <Text style={{ color: 'red', marginTop: 10 }}>{errorMessage}</Text> : null}
            {data && messages.length == 0 ? <Text style={{ color: 'black', marginTop: 10 }}>{ "No data found" }</Text> : null}
            <FlatList
              data={messages}
              keyExtractor={(item, index) => index.toString()}
              renderItem={({ item }) => (
                <List.Item
                  title={() => (
                    <View style={{ flexDirection: 'column' }}>
                        <Text variant="labelSmall">From Fan ID: {item.client_id}</Text>
                        <Text variant="labelSmall">{formatTimestampToHumanReadable(item.ts.value)}</Text>
                        <View style={{ flex: 1, marginBottom: 5, paddingRight: 10 }}>
                            <Text style={{ marginBottom: 5 }}><b>Question</b>: {extractUserMessage(item.user_text)}</Text>
                            <Text><b>Reply</b>: {item.reply}</Text>
                            <Text><b>Model Type</b>: {item.gptModelType}</Text>
                        </View>
                    </View>
                  )}
                />
              )}
              ItemSeparatorComponent={() => (
                <View style={{ height: 1, width: "100%", backgroundColor: "#C8C8C8" }} />
              )}
            />
            {data && messages.length !== 0 ? (
              <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginVertical: 10 }}>
                <Button
                  mode="outlined"
                  onPress={fetchPrevMessages}
                  disabled={page <= 1}
                  style={{ flex: 1, marginRight: 5 }}
                >
                  Previous
                </Button>
                <Text style={{ flex: 1, textAlign: 'center' }}>
                  Page {page}
                </Text>
                <Button
                  mode="outlined"
                  onPress={fetchNextMessages}
                  disabled={!data.hasNextPage}
                  style={{ flex: 1, marginLeft: 5 }}
                >
                  Next
                </Button>
              </View>
            ) : null}
          </View> 
      }
    </View>
  );
};

function extractUserMessage(text: string) {
  const marker = "user message:";
  const index = text.toLowerCase().indexOf(marker); // case-insensitive search

  if (index === -1) {
      return text; // return original text if "user message:" is not found
  }

  return text.slice(index + marker.length).trim();
}

function formatTimestampToHumanReadable(timestamp: string): string {
  const date = new Date(timestamp);
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric', month: 'short', day: 'numeric',
    hour: '2-digit', minute: '2-digit', second: '2-digit',
    timeZoneName: 'short'
  };
  return new Intl.DateTimeFormat('en-US', options).format(date);
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 16,
    paddingVertical: 8 
  },
  formField: {
    marginVertical: 8,
  },
  formButton: {
    marginVertical: 8,
  }
});

export default CreatorDashboardScreen;