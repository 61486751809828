import React, { useLayoutEffect, useEffect, useState } from 'react';
import { Button } from 'react-native-paper';
import { View, Text, StyleSheet, Image, TextInput, TouchableHighlight } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useIsMobileWeb } from "../hooks";
import { LandingScreenNativationProp } from '../navigation/navigationTypes';
import { useTheme } from "react-native-paper";
import { useSpring, animated } from '@react-spring/web'
import InitialTextContext from '../context/InitialTextContext';

const topImage = require('../../assets/profgai-landing-top.png')
const bottomImage = require('../../assets/profgai-landing-bottom.png')
const fullHeadImage = require('../../assets/profgai-landing-full-head.png')
const sendIcon = require('../../assets/ic_round-send.png')

const LandingScreen = () => {
  const navigation = useNavigation<LandingScreenNativationProp>();
  const theme = useTheme();
  const isMobileWeb = useIsMobileWeb();
  const [inputText, setInputText] = useState('');
  const [displayHeads, setDisplayHeads] = useState(false);
  const contextValue = React.useContext(InitialTextContext);
  const ANIMATION_DELAY = 600 

  useLayoutEffect(() => {
    navigation.setOptions({
      title: "ProfG.AI",
    });
  }, [navigation, isMobileWeb]);

  useLayoutEffect(() => {
    setTimeout(() => {
      setDisplayHeads(true); 
    }, ANIMATION_DELAY);
  }, []);

  const handlePressedSend = () => {
    contextValue.setInitialText(inputText)
    setInputText("")
    navigation.navigate("Main")
  }

  const fullHeadAnim = useSpring({
    from: { 
      scale: 1,
      opacity: 1
    },
    to: { 
      scale: 1,
      opacity: 0
    },
    delay: ANIMATION_DELAY + 850,
    config: { 
      duration: 150
    }
  });

  const topHeadAnim = useSpring({
    from: { 
      scale: 0.335, 
      top: '50%',
      left: '50%',
      marginTop: -330,
      rotate: 5
    },
    to: { 
      scale: isMobileWeb ? 0.65 : 1,
      top: '0%',
      left: isMobileWeb ? '20%' : '40%',
      marginTop: -550,
      rotate: -30
    },
    delay: ANIMATION_DELAY + 1000,
    config: {
      duration: 550
    }
  });

  const bottomHeadAnim = useSpring({
    from: { 
      scale: 0.42,
      top: '50%',
      marginTop: -455,
    },
    to: { 
      scale: isMobileWeb ? 0.65 : 1,
      top: '100%',
      marginTop: -550
    },
    delay: ANIMATION_DELAY + 1000,
    config: {
      duration: 550
    }
  });

  const descriptionAnim = useSpring({
    from: {
      opacity: 0
    },
    to: { 
      opacity: 1
    },
    delay: ANIMATION_DELAY + 1300
  })

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden"
  },
  descriptionContainer: {
    flex: 1,
    alignItems: "center",
    alignSelf: "center",
    marginTop: isMobileWeb ? -200 : -300
  },
  title: {
    fontSize: isMobileWeb ? 60 : 150,
    fontFamily: "InterstateCondensedBold",
    marginBottom: 10,
    color: "white",
    zIndex: 1,
  },
  description: {
    fontSize: isMobileWeb ? 18 : 32,
    fontFamily: "InterstateCondensedRegular",
    textAlign: "center",
    color: "black",
    marginBottom: 20,
    paddingHorizontal: isMobileWeb ? 20 : "min(400, '35%')",
    zIndex: 1,
  },
  button: {
    marginTop: 20,
    zIndex: 1,
  },
  inputContainer: {
    position: 'relative',
    marginTop: isMobileWeb ? 15 : 20,
    marginBottom: 20,
    width: isMobileWeb ? 320 : 600, 
    flex: 1,
    flexDirection: "row",
    alignContent: "stretch",
    backgroundColor: 'white',
    border: 'none',
    borderRadius: 50,    
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 3 },
    shadowOpacity: 0.3,
    shadowRadius: 5,  
    elevation: 5,
  },
  sendButton: {
    width: 26,
    height: 26,
    display: "flex",
    flexDirection: "column",
    alignSelf: "flex-end",
    marginRight: 15,
    marginBottom: 11
  },
  sendIcon: {
    width: 26, 
    height: 26,
    alignSelf: "center"
  },
  askScottInput: {
    paddingHorizontal: 30,
    minHeight: 48,
    color: '#555',
    outlineStyle: 'none',
    textAlignVertical: 'top',
    flexGrow: 1,
  },
  imageTop: {
    width: 640,
    height: 431,
    opacity: displayHeads ? 1 : 0
  },
  imageBottom: {
    width: 600,
    height: 980,
    opacity: displayHeads ? 1 : 0
  },
  fullHead: {
    width: 232, 
    height: 334,
    zIndex: 1
  }
});

  return (
    <View style={[styles.container, { backgroundColor: theme.colors.primary }]}>
      <animated.div 
        style={{ 
          width: 232, 
          height: 334,
          marginLeft: -116,
          marginTop: -157,
          position: "absolute",
          top: '50%',
          left: '50%',
          ...fullHeadAnim
        }}
        >
        <Image source={fullHeadImage} style={styles.fullHead} />
      </animated.div>
      <animated.div 
        style={{
          width: 640,
          height: 431,
          position: "absolute",
          marginLeft: -320,
          left: '50%',
          ...topHeadAnim 
        }}>
        <Image source={topImage} style={styles.imageTop} />
      </animated.div>
      <animated.div 
        style={{
          width: 600,
          height: 980,
          position: "absolute",
          marginLeft: -300,
          left: '50%',
          ...bottomHeadAnim 
        }}>
        <Image source={bottomImage} style={styles.imageBottom} />
      </animated.div>
      <animated.div style={{ 
          display: 'flex',
          flex: 1,
          alignItems: isMobileWeb ? "center" : "top",
          ...descriptionAnim 
        }}>
        <View style={styles.descriptionContainer}>
          <Text style={styles.title}>ProfG.AI</Text>
          <Text style={styles.description}>Let's light this candle...</Text>

          <View 
            style={ styles.inputContainer }>
            <TextInput 
              // multiline={true}
              placeholder="Ask Scott..."
              style={styles.askScottInput}
              value={inputText}
              onChangeText={setInputText}
              onSubmitEditing={handlePressedSend}
            /> 
            <TouchableHighlight 
              onPress={handlePressedSend}
              style={styles.sendButton}
              underlayColor='none'
            >
              <Image source={sendIcon} style={styles.sendIcon} />
            </TouchableHighlight> 
          </View>
        </View>
      </animated.div>
    </View>
  );
};

export default LandingScreen;
