import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { StyleSheet, View, Text, Linking, Dimensions, Modal, Animated, Platform } from "react-native";
import { useAppSelector, useIsMobileWeb } from "../hooks";
import Chat from "../components/chat/Chat";
import ChatScreenTitle from "../components/chat/ChatScreenTitle";
import {
  ChatScreenNavigationProp,
  ChatScreenRouteProp,
} from "../navigation/navigationTypes";
import { useNavigation, useRoute } from "@react-navigation/native";
import { useTheme } from "react-native-paper";
import { useLogMutation } from "../api/logging";
import TermsOfServiceModal from "./TermsOfServiceModal";
import AboutScreen from "./AboutScreen";

const ChatScreen = () => {
  const {
    params: { creatorId },
  } = useRoute<ChatScreenRouteProp>();
  const navigation = useNavigation<ChatScreenNavigationProp>();

  const clientId = useAppSelector((state) => state.user.clientId);
  const [log] = useLogMutation();
  const creators = useAppSelector((state) => state.creators.creatorModels);
  const selectedCreator = creators.find((c) => c.id === creatorId);
  const isMobileWeb = useIsMobileWeb();
  const theme = useTheme();
  const [isRateLimited, setIsRateLimited] = useState(false);
  const windowWidth = Dimensions.get('window').width;
  const [isTermsVisible, setIsTermsVisible] = useState(false);
  const [isAboutAnimatingIn, setIsAboutAnimatingIn] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);
  const position = useRef(new Animated.ValueXY({ x: -Dimensions.get('window').width, y: 0 })).current;

  const handlePressAttribution = () => {
    Linking.openURL('https://spirito.ai/');
  };

  const handlePressToS = () => {
    setIsTermsVisible(true);
  };

  const handleRateLimit = () => {
    setIsRateLimited(true);
  };

  const slide = (toValue: number, onComplete: () => void) => {
    Animated.timing(position.x, {
      toValue: toValue,
      duration: 400,
      useNativeDriver: false,
    }).start(onComplete);
  };

  const isMobileBrowser = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  };

  // Handle keyboard for mobile web
  useEffect(() => {
    const handleResize = () => {
      if (visualViewport && isMobileBrowser()) {
        const isKeyboardNowVisible = visualViewport.height < visualViewport.width;
        setIsKeyboardVisible(isKeyboardNowVisible);
      }
    };

    visualViewport?.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      visualViewport?.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (isAboutAnimatingIn) {
      setModalVisible(true);
      slide(0, () => {});
    } else {
      slide(-Dimensions.get('window').width, () => setModalVisible(false));
    }
  }, [isAboutAnimatingIn]);
  
  useLayoutEffect(() => {
    if (selectedCreator) {
      navigation.setOptions({
        title: `ProfG.AI | Chat`,
        header: (props) => (
          <ChatScreenTitle
            selectedCreator={selectedCreator}
            onGoBackPress={() => setIsAboutAnimatingIn(true)}
            isMobileWeb={isMobileWeb}
          />
        ),
        headerStyle: {
          height: 81,
        },
      });
    }
  }, [navigation, selectedCreator, isMobileWeb]);

  useEffect(() => {
    log({
      clientId,
      data: {
        client_event: "main_chat_screen_opened",
        creator_id: creatorId,
      },
    });
  }, [creatorId]);

  const styles = StyleSheet.create({
    container: {
      backgroundColor: theme.colors.secondaryContainer,
      flexDirection: "column",
      flex: 1,
    },
    content: {
      flexDirection: "column",
      flex: 1,
      flexGrow: 1,
      minHeight: 300,
      paddingHorizontal: isMobileWeb ? 10 : Math.max(20, Math.min(windowWidth - (320 + 700), 160)),
      paddingBottom: isKeyboardVisible ? 15 : 0,
    },
    poweredBy: {
      textAlign: 'center',
      paddingTop: 0,
      paddingBottom: 7,
      fontSize: 14,
      fontFamily: "Roboto-Regular",
      color: "#989898",
    },
    termsOfService: {
      textAlign: 'center',
      paddingBottom: 10,
      fontSize: 13,
      fontFamily: "Roboto-Regular",
      color: "#989898",
      paddingHorizontal: 10,
    },
    rateLimitedOverlay: {
      flexDirection: 'column',
      height: 45,
      backgroundColor: '#F4B547',
      justifyContent: 'center',
      alignItems: 'center',
    },
    rateLimitedText: {
      fontSize: 15,
      fontFamily: "Roboto-Regular",
      color: 'black',
      padding: 15 
    },
    fullHeight: {
      flex: 1,
      height: '100%',
    },
    hidden: {
      display: "none",
    }
  });
  return (
    <View style={styles.container}>
      {isRateLimited && (
        <View style={styles.rateLimitedOverlay}>
          <Text style={styles.rateLimitedText}>
            We're sorry, ProfG.AI rate limited exceeded. Limit resets after one hour.
          </Text>
        </View>
      )}
      <View style={styles.content}>
        {selectedCreator && (
          <Chat 
            key={selectedCreator.id} 
            selectedCreator={selectedCreator}
            onRateLimit={handleRateLimit}
          />
        )}
        <Text style={[styles.poweredBy, isKeyboardVisible && styles.hidden]}>
          Powered by{' '}
          <Text style={{ textDecorationLine: 'underline' }} onPress={handlePressAttribution}>
            Spirito AI
          </Text>
        </Text>
        <Text style={[styles.termsOfService, isKeyboardVisible && styles.hidden]}>
          The services are provided "as is," "with all faults," and "as available." {' '}
          <Text style={{ textDecorationLine: 'underline' }} onPress={handlePressToS}>
            Read the full terms
          </Text>
        </Text>
      </View>
      <TermsOfServiceModal
        visible={isTermsVisible}
        onClose={() => setIsTermsVisible(false)}
      />
      <Modal
        visible={modalVisible}
        animationType="none"
        transparent={true}
      >
      <Animated.View style={[styles.fullHeight, position.getLayout()]}>
        <AboutScreen onExplorePress={() => setIsAboutAnimatingIn(false)} />
      </Animated.View>
      </Modal>
    </View>
  );
};

export default ChatScreen;
