import React, { useState, useEffect, useRef } from "react";
import { StyleSheet, View } from "react-native";
import {
  Bubble,
  GiftedChat,
  IMessage,
  InputToolbar,
  MessageText,
} from "react-native-gifted-chat-forked";
import { EXPRESS_WEBSOCKET_URL } from "../../api/config";
import { useAppDispatch, useAppSelector, useIsMobileWeb } from "../../hooks";
import {
  addMessage,
  checkAndAddStreamedMessage,
  createUserFromCreator,
  createMessage,
  createFanMessage,
} from "../../slices/messagesSlice";
import {
  createStreamingChatMessage,
  sliceMesssages,
} from "../../api/responders";
import TypingIndicator from "./TypingIndicator";
import ServerErrorNotification from "./ServerErrorNotification";
import { ChatComposer } from "./ChatComposer";
import { 
  IChatResponder, 
  IChatMessageWithResponderModel, 
  POLLING_END_STRING, 
  createCacheKey 
} from "../../common/models/responders";
import { FAN } from "../../const";
import { useTheme } from "react-native-paper";
import { useLogMutation } from "../../api/logging";
import SendButton from "./SendButton";
import InitialTextContext from "../../context/InitialTextContext";

const Chat = ({ selectedCreator, onRateLimit }: { 
  selectedCreator: IChatResponder
  onRateLimit?: () => void
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const [selectedCreatorMessages, setSelectedCreatorMessages] = useState<
    IMessage[]
  >([]);
  const [text, setText] = useState<string>("");
  const [isRateLimited, setIsRateLimited] = useState<boolean>(false);
  const [isServerError, setIsServerError] = useState<boolean>(false);
  const [shouldStream, setShouldStream] = useState<boolean>(false);

  const messages = useAppSelector((state) => state.messages);
  const clientId = useAppSelector((state) => state.user.clientId);
  const [log] = useLogMutation();
  const wsRef = useRef<WebSocket | null>(null);
  const currRequestHashRef = useRef<string>("");
  const { initialText, setInitialText } = React.useContext(InitialTextContext);
  const isMobileWeb = useIsMobileWeb();

  const initializeWebSocket = () => {
    if (typeof EXPRESS_WEBSOCKET_URL !== 'string') {
      throw new Error('REACT_APP_EXPRESS_WEBSOCKET_URL must be defined and start with ws');
    }
    
    const ws = new WebSocket(EXPRESS_WEBSOCKET_URL);
    wsRef.current = ws;

    ws.onopen = (event: Event) => {
      console.log('Connected to WebSocket server', event);
      // Send an initial message to subscribe to a specific responderId
      const subscriptionMessage = {
        type: 'subscribe',
        responderId: selectedCreator.id // Replace with the actual responderId
      };
      ws.send(JSON.stringify(subscriptionMessage));

      // If we are reloading, and the last message was from a FAN, hit the message endpoint again.
      if (messages.creatorIDToMessages[selectedCreator.id]?.length > 0 && 
        messages.creatorIDToMessages[selectedCreator.id][0].user._id === FAN._id) {
          const messageToSend = createStreamingChatMessage(clientId, selectedCreator.id, FAN.name, messages.creatorIDToMessages[selectedCreator.id]);
          currRequestHashRef.current = createCacheKey(selectedCreator.id, FAN.name, sliceMesssages(messages.creatorIDToMessages[selectedCreator.id]));
          ws.send(messageToSend);
        }
    };

    ws.onmessage = (event: MessageEvent) => {
      try {
        const data = JSON.parse(event.data);
        setIsServerError(false);
        if (data.type === "chat_streaming_message" && data.requestHash === currRequestHashRef.current) {
          data.messages?.forEach((message: IChatMessageWithResponderModel) => {
            if (message.text === POLLING_END_STRING) {
              setShouldStream(false);
            } else {
              dispatch(
                checkAndAddStreamedMessage({
                  creatorID: selectedCreator.id,
                  message: createMessage(selectedCreator, message),
                })
              );
            }
          });
        } else if (data.type === "rate_limit") {
          setIsRateLimited(true);
        } else if (data.type === "error") {
          setIsServerError(true);
        }
      } catch (error) {
        console.error("An error occurred while parsing the JSON data:", error);
      }
    };

    ws.onerror = (error) => {
      // Handle any errors that occur
      console.error("WebSocket Error: ", error);
    };

    // Cleanup: close the WebSocket connection when the component unmounts
    return () => {
      console.log("closing web socket");
      ws.close();
    }
  }

  // WebSocket connection
  useEffect(() => {
    const cleanupWebSocket = initializeWebSocket();

    return () => cleanupWebSocket();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (initialText && initialText.length > 0) {
        onSend([createFanMessage(initialText)]);
        setInitialText("");
      }
    }, 500);
  },[]);

  useEffect(() => {
    setSelectedCreatorMessages(
      messages.creatorIDToMessages[selectedCreator.id] ?? []
    );
    log({
      clientId,
      data: {
        client_event: "chat_messages_updated",
        creator_id: selectedCreator.id,
        num_messages:
          messages.creatorIDToMessages[selectedCreator.id]?.length ?? 0,
      },
    });
  }, [messages]);

  useEffect(() => {
    if (selectedCreatorMessages.length > 0) {
      if (selectedCreatorMessages[0].user._id === FAN._id) {
        setShouldStream(true);
      }
    }
  }, [selectedCreatorMessages]);

  const onSend = (newMessages: IMessage[]) => {
    if (newMessages.length === 1 && newMessages[0].text === "") {
      // Do not allow an empty message to be sent
      return;
    }
    const sendMessage = (modifiedMessage: IMessage) => {
      if (wsRef.current) {
        const messageToSend = createStreamingChatMessage(clientId, selectedCreator.id, FAN.name, [modifiedMessage, ...selectedCreatorMessages]);
        currRequestHashRef.current = createCacheKey(selectedCreator.id, FAN.name, sliceMesssages([modifiedMessage, ...selectedCreatorMessages]));
        wsRef.current.send(messageToSend);
      } else {
        console.error('WebSocket has no current ref. Cannot send message.');
      }
    };
    for (const newMessage of newMessages) {
      // In order for serialization, createdAt needs to be number not a Date
      const modifiedMessage = {
        ...newMessage,
        createdAt:
          typeof newMessage.createdAt === "object"
            ? newMessage.createdAt.getTime()
            : newMessage.createdAt,
      };
      if (selectedCreator.id) {
        dispatch(
          addMessage({
            creatorID: selectedCreator.id,
            message: modifiedMessage,
          })
        );
        const sendAndCleanup = () => {
          if (wsRef.current) {
            sendMessage(modifiedMessage);
            // Remove the event listener to avoid duplicate messages
            wsRef.current.removeEventListener('open', sendAndCleanup);
          }
        }
        if (wsRef.current) {
          switch(wsRef.current.readyState) {
            case WebSocket.OPEN:
              sendMessage(modifiedMessage);
              break;
            case WebSocket.CLOSED:
              initializeWebSocket();
              wsRef.current.addEventListener('open', sendAndCleanup);
              break;
            case WebSocket.CONNECTING:
              wsRef.current.addEventListener('open', sendAndCleanup);
              break;
          }
        } else {
          console.error('WebSocket is not open, has no current ref. Cannot send message.');
        }
      }
    }
    log({
      clientId,
      data: {
        client_event: "chat_on_send",
        creator_id: selectedCreator.id,
      },
    });
  };

  const renderFooter = () => {
    // If error from server, show error so we don't keep "typing"".
    if (isServerError) {
      return <ServerErrorNotification />;
    } else if (isRateLimited) {
      return;
    }
    // Otherwise show typing indicator if appropriate
    return (selectedCreatorMessages.length > 0 &&
      selectedCreatorMessages[0].user._id === FAN._id) ||
      shouldStream ? (
      <TypingIndicator
        isTyping={true}
        user={createUserFromCreator(selectedCreator)}
      />
    ) : null;
  };

  useEffect(() => {
    if (isRateLimited) {
      onRateLimit && onRateLimit();
      log({
        clientId,
        data: {
          client_event: "chat_show_rate_limit",
          creator_id: selectedCreator.id,
        },
      });
    }
  }, [isRateLimited]);

  return (
    <View style={{...styles.chatContainer}}>
      <GiftedChat
        messages={selectedCreatorMessages}
        text={text}
        onInputTextChanged={setText}
        onSend={onSend}
        user={FAN}
        alwaysShowSend={true}
        renderAvatarOnTop={true}
        renderFooter={renderFooter}
        renderComposer={ChatComposer}
        /* generally, left = bot message, right = user msg */ 
        renderMessageText={(props) => (
          <MessageText
            {...props}
            linkStyle={{
              right: { color: theme.colors.onSecondaryContainer },
              left: { color: theme.colors.onSecondaryContainer },
            }}
            textStyle={{
              left: {
                marginHorizontal: 0,
                color: theme.colors.onSecondaryContainer,
                fontSize: isMobileWeb ? 14 : 18,
                lineHeight: isMobileWeb ? 20 : 29,
                fontFamily: "Roboto-Regular",
              },
              right: { 
                marginHorizontal: 0, 
                color: theme.colors.onSecondaryContainer,
                fontSize: isMobileWeb ? 14 : 18,
                lineHeight: isMobileWeb ? 20 : 29,
                fontFamily: "Roboto-Regular",
              },
            }}
            containerStyle={{
              left: { 
                marginHorizontal: 10,
              },
              right: { 
                marginHorizontal: 10, 
                marginVertical: isMobileWeb ? 10 : 20
              },
            }}
          />
        )}
        messagesContainerStyle={{ 
          backgroundColor: theme.colors.secondaryContainer
        }}
        renderBubble={(props) => {
          return (
            <Bubble
              {...props}
              wrapperStyle={{
                left: {
                  backgroundColor: theme.colors.onPrimary,
                  marginRight: 20
                },
                right: {
                  marginLeft: 0,
                  backgroundColor: theme.colors.secondaryContainer,
                },
              }}
              containerStyle={{
                right: {
                  alignItems: "flex-start",
                  marginLeft: 0,
                }
              }}
            />
          );
        }}
        renderDay={() => null}
        renderTime={() => null}
        minInputToolbarHeight={67}
        renderInputToolbar={(props) => (
          <InputToolbar
            {...props}
            containerStyle={{
              marginBottom: 10,
              borderRadius: 30,
              borderTopWidth: 0,
              shadowColor: "black",  // shadow color
              shadowOffset: {
                width: 0,
                height: 0,
              },
              shadowOpacity: 0.15,
              shadowRadius: 5,
              elevation: 5, // for Android
            }}
          />
        )}
        renderSend={(props) => (
          <SendButton 
            {...props} 
          />
        )}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  chatContainer: {
    flex: 1,
    height: "100%",
  },
});

export default Chat;
