import React, { FC, ReactNode, useEffect } from "react";
import { usePrefetch } from "../api/responders";
import { useAppSelector } from "../hooks";
import { useLogMutation } from "../api/logging";
import { IS_DEMO } from "../api/config";
import { useAppDispatch } from "../hooks";
import { useGetAdminConfigQuery } from "../api/adminConfig";
import { setIsAdminSite } from "../slices/appConfigSlice";

const Startup: FC<{ children: ReactNode }> = ({ children }) => {
  const prefetchChatRespondersMeta = usePrefetch("getChatRespondersMeta");
  const clientId = useAppSelector((state) => state.user.clientId);
  const dispatch = useAppDispatch();
  const { data: adminData, isLoading: isAdminLoading } = useGetAdminConfigQuery();
  const [log] = useLogMutation();

  useEffect(() => {
    const isDemo = IS_DEMO == "1";
    prefetchChatRespondersMeta({ clientId, isDemo });
    log({ clientId, data: { client_event: "startup" } });
  }, []);

  useEffect(() => {
    if (!isAdminLoading && adminData) {
      dispatch(setIsAdminSite(adminData.isAdminSite));
    }

  }, [isAdminLoading, adminData, dispatch]);

  return <>{children}</>;
};

export default Startup;
