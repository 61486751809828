import React, { useState, ReactNode } from 'react';

interface InitialTextContextProps {
  initialText: string;
  setInitialText: React.Dispatch<React.SetStateAction<string>>;
}

const InitialTextContext = React.createContext<InitialTextContextProps>({
  initialText: '',
  setInitialText: () => {},
});

interface InitialTextProviderProps {
  children: ReactNode;
}

export const InitialTextProvider: React.FC<InitialTextProviderProps> = ({ children }) => {
  const [initialText, setInitialText] = useState<string>('');

  return (
    <InitialTextContext.Provider value={{ initialText, setInitialText }}>
      {children}
    </InitialTextContext.Provider>
  );
};

export default InitialTextContext;
