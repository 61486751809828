import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IChatResponder, IChatMessageWithResponderModel } from "../common/models/responders";
import { FAN } from "../const";
import { IChatMessageWithLLMModel } from "../interfaces";

export const createUserFromCreator = (creator: IChatResponder) => {
  return {
    _id: creator.id ?? 1,
    name: creator.name,
    avatar: creator.avatarUrl,
  };
};

export const createMessage = (responder: IChatResponder, message: IChatMessageWithResponderModel) => ({
  _id: Math.round(Math.random() * 1000000),
  text: message.text,
  createdAt: new Date().getTime(),
  user: createUserFromCreator(responder),
  llmModel: message.model,
});

export const createFanMessage = (text: string) => ({
  _id: Math.round(Math.random() * 1000000),
  text: text,
  createdAt: new Date().getTime(),
  user: FAN,
});

export interface MessagesState {
  creatorIDToMessages: {
    [creatorID: string]: IChatMessageWithLLMModel[];
  };
}

const initialState: MessagesState = {
  creatorIDToMessages: {},
};

export const messagesSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {
    addMessage: (
      state,
      {
        payload: { creatorID, message },
      }: PayloadAction<{
        creatorID: string;
        message: IChatMessageWithLLMModel;
      }>
    ) => {
      if (!state.creatorIDToMessages[creatorID]) {
        state.creatorIDToMessages[creatorID] = [];
      }
      state.creatorIDToMessages[creatorID] = [message, ...state.creatorIDToMessages[creatorID]];
    },
    checkAndAddStreamedMessage: (
      state,
      {
        payload: { creatorID, message },
      }: PayloadAction<{ creatorID: string; message: IChatMessageWithLLMModel }>
    ) => {
      if (!state.creatorIDToMessages[creatorID]) {
        state.creatorIDToMessages[creatorID] = [];
      }
      // Get the last 10 messages
      const lastMessages = state.creatorIDToMessages[creatorID].slice(0, 10);

      // Check if the incoming message exists in the last 10 messages
      const messageExists = lastMessages.some(existingMessage => existingMessage.text === message.text);

      // De-dup messages as they come in, but also allow repeater and 1st message from bot to be added
      if (!messageExists || creatorID === "repeater" || (lastMessages.length > 0 && lastMessages[0].user._id === FAN._id)) {
        // Add the message if it doesn't exist in the last 10 messages
        state.creatorIDToMessages[creatorID] = [message, ...state.creatorIDToMessages[creatorID]];
      }
    },
    clearMessagesForCreator: (
      state,
      {
        payload: creatorID,
      }: PayloadAction<string>
    ) => {
      if (state.creatorIDToMessages[creatorID] && state.creatorIDToMessages[creatorID].length > 0) {
        state.creatorIDToMessages[creatorID] = [];
      }
    },
  },
});

export const { addMessage, checkAndAddStreamedMessage, clearMessagesForCreator } = messagesSlice.actions;

export default messagesSlice.reducer;
