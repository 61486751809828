import { createSlice } from "@reduxjs/toolkit";

export interface AppConfig {
  isAdminSite: boolean;
}

const initialState: AppConfig = {
  isAdminSite: false,
};

export const appConfigSlice = createSlice({
  name: "appConfig",
  initialState,
  reducers: {
    setIsAdminSite: (state, action) => {
      state.isAdminSite = action.payload;
    }
  },
});

export const { setIsAdminSite } = appConfigSlice.actions;

export default appConfigSlice.reducer;
