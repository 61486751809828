import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import type { RootState, AppDispatch } from "../store";
import { useWindowDimensions } from "react-native";

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useIsMobileWeb = () => {
  const dimensions = useWindowDimensions();
  return dimensions.width < 756;
};
