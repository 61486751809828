import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import { IChatResponder } from "../common/models/responders";

export interface CreatorsState {
  creatorModels: IChatResponder[];
}

const initialState: CreatorsState = {
  creatorModels: [],
};

export const creatorsSlice = createSlice({
  name: "creators",
  initialState,
  reducers: {
    setCreators: (state, action: PayloadAction<IChatResponder[]>) => {
      state.creatorModels = action.payload;
    },
  },
});

export const { setCreators } = creatorsSlice.actions;

export default creatorsSlice.reducer;
