import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { EXPRESS_BASE_URL } from "./config";

export const loggingApi = createApi({
  reducerPath: "loggingApi",
  baseQuery: fetchBaseQuery({
    baseUrl: EXPRESS_BASE_URL,
  }),
  endpoints: (builder) => ({
    log: builder.mutation<
      object,
      { clientId: string; data: Record<string, unknown> }
    >({
      query: ({ clientId, data }) => {
        return {
          url: `log?clientId=${clientId}`,
          body: data,
          method: "POST",
        };
      },
    }),
  }),
});

export const { useLogMutation } = loggingApi;
