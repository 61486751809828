import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { EXPRESS_BASE_URL } from "./config";

export const adminConfigApi = createApi({
  reducerPath: "adminConfigApi",
  baseQuery: fetchBaseQuery({
    baseUrl: EXPRESS_BASE_URL,
  }),
  endpoints: (builder) => ({
    getAdminConfig: builder.query<{ isAdminSite: boolean }, void> ({
      query: () => ({
        url: "is_admin_site",
        method: "GET",
      }),
    }),
  })
});

export const { useGetAdminConfigQuery } = adminConfigApi;