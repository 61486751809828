import React from 'react';
import { Text, View, StyleSheet, TouchableOpacity, Linking } from 'react-native';
import { IconButton, useTheme } from 'react-native-paper';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { clearMessagesForCreator } from '../../slices/messagesSlice';
import { IChatResponder } from '../../common/models/responders';
import LinkOutHeaders from './LinkOutHeaders';
import { useIsMobileWeb } from '../../hooks';

const ChatScreenTitle = ({ selectedCreator, onGoBackPress, isMobileWeb }: 
  { selectedCreator: IChatResponder; onGoBackPress: () => void; isMobileWeb: boolean }) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const handlePress = async () => {
    // Check if the url can be opened
    const supported = await Linking.canOpenURL(selectedCreator.socialUrl);

    if (selectedCreator.socialUrl !== "" && supported) {
      await Linking.openURL(selectedCreator.socialUrl);
    }
  };

  const styles = StyleSheet.create({
    headerContainer: {
      flexDirection: "row",
      paddingTop: isMobileWeb ? 7 : 15,
      paddingBottom: isMobileWeb ? 7 : 15,
      alignItems: "center"
    },
    linkOutHeaderContainer: {
      paddingRight: 30,
    },
    divider: {
      height: 1,
      alignSelf: "stretch",
    },
    moreButton: {
      padding: 10,
      marginLeft: 20,
    }
  });

  return (
    <View style={{backgroundColor: theme.colors.secondaryContainer}}>
      <View style={[styles.headerContainer, {justifyContent: isMobileWeb ? "space-between" : "flex-end"}]}>
        {isMobileWeb && 
          <IconButton 
            icon="dots-horizontal"
            style={styles.moreButton}
            onPress={onGoBackPress}
          />}
        {/* @alliscyao keeping for debug purposes */}
        {/* {(
          <IconButton 
            icon="delete-forever"
            onPress={() => {
              dispatch(clearMessagesForCreator(selectedCreator.id))
            }}
            style={{marginRight: 30}} 
          />
        )} */}
        <View style={styles.linkOutHeaderContainer}>
          <LinkOutHeaders />
        </View>
      </View>
      <View style={[styles.divider, {backgroundColor: "#E2E2E2"}]} />
    </View>
  );
};

export default ChatScreenTitle;