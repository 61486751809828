import AsyncStorage from "@react-native-async-storage/async-storage";
import { persistStore, persistReducer } from "redux-persist";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { responderApi } from "./api/responders";
import { feedbackApi } from "./api/feedback";
import { fanResponsesApi } from "./api/fanResponses";
import { loggingApi } from "./api/logging";
import { linkPreviewApi } from "./api/linkPreview";
import { adminConfigApi } from "./api/adminConfig";
import creatorsSlice from "./slices/creatorsSlice";
import messagesSlice from "./slices/messagesSlice";
import userSlice from "./slices/userSlice";
import appConfigSlice from "./slices/appConfigSlice";

const rootPersistConfig = {
  key: "root",
  storage: AsyncStorage,
  whitelist: ["creators", "messages", "user"],
};

const rootReducer = combineReducers({
  creators: creatorsSlice,
  messages: messagesSlice,
  user: userSlice,
  appConfig: appConfigSlice,
  [responderApi.reducerPath]: responderApi.reducer,
  [feedbackApi.reducerPath]: feedbackApi.reducer,
  [loggingApi.reducerPath]: loggingApi.reducer,
  [fanResponsesApi.reducerPath]: fanResponsesApi.reducer,
  [linkPreviewApi.reducerPath]: linkPreviewApi.reducer,
  [adminConfigApi.reducerPath]: adminConfigApi.reducer,
});

const persistedRootReducer = persistReducer(rootPersistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedRootReducer,
  middleware: (getDefaultMiddleware) => {
    var middlewareArray = getDefaultMiddleware({
      serializableCheck: false, // TODO: [gigz] Is this right?
    }).concat([
      responderApi.middleware,
      feedbackApi.middleware,
      fanResponsesApi.middleware,
      loggingApi.middleware,
      linkPreviewApi.middleware,
      adminConfigApi.middleware,
    ]);
    return middlewareArray;
  },
});

export const persistor = persistStore(store);
// persistor.purge();

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
