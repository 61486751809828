import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { EXPRESS_BASE_URL, NUM_MESSAGES_TO_SEND } from "./config";
import {
  IChatMessageType,
  IChatResponder,
} from "../common/models/responders";
import { setCreators } from "../slices/creatorsSlice";
import { FAN } from "../const";
import { IChatMessageWithLLMModel } from "../interfaces";

export const responderApi = createApi({
  reducerPath: "responderApi",
  baseQuery: fetchBaseQuery({
    baseUrl: EXPRESS_BASE_URL,
    timeout: 25000,
  }),
  tagTypes: ["ChatResponderMeta"],
  endpoints: (builder) => ({
    getChatRespondersMeta: builder.query<IChatResponder[], { clientId: string; isDemo: boolean }>({
      query: ({ clientId, isDemo }) => ({
        url: `chat_responders?clientId=${clientId}&isDemo=${isDemo.toString()}`,
        method: "GET",
      }),
      providesTags: () => [
        {
          type: "ChatResponderMeta",
          id: "LIST",
        },
      ],
      onCacheEntryAdded: async (_, { cacheDataLoaded, dispatch }) => {
        const responders = (await cacheDataLoaded).data as IChatResponder[];
        dispatch(setCreators(responders));
      },
    }),
  }),
});

export const sliceMesssages = (messages: IChatMessageWithLLMModel[]) => {
  return messages.slice(0, NUM_MESSAGES_TO_SEND).map((message) => ({
    type:
      message.user._id === FAN._id
        ? IChatMessageType.User
        : IChatMessageType.Bot,
    text: message.text,
    model: message.llmModel ?? "",
  }));
}

export const createStreamingChatMessage = (
  clientId: string,
  responderId: string,
  fanName: string,
  messages: IChatMessageWithLLMModel[],
) => {
  return JSON.stringify({
    type: "chat_streaming_message",
    clientId,
    responderId,
    fanName,
    messages: sliceMesssages(messages),
  });
};

export const {
  useGetChatRespondersMetaQuery,
  usePrefetch,
} = responderApi;
