import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { EXPRESS_BASE_URL } from "./config";
import { IFanResponseRequest, IFanResponsePagedResponse } from "../common/models/creatorDashboard/fanResponse";

export const fanResponsesApi = createApi({
  reducerPath: "fanResponsesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: EXPRESS_BASE_URL,
  }),
  endpoints: (builder) => ({
    fetchFanResponses: builder.mutation<IFanResponsePagedResponse, { clientId: string; fanResponseReq: IFanResponseRequest }>({
      query: ({ clientId, fanResponseReq }) => ({
        url: `fan_responses?clientId=${clientId}`,
        body: fanResponseReq,
        method: "POST",
      }),
    }),
  }),
});

export const { useFetchFanResponsesMutation } = fanResponsesApi;
