import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { EXPRESS_BASE_URL } from "./config";
import { ILinkPreviewData } from "../common/models/linkPreview/linkPreviewDataModel";
import { IFanResponseRequest, IFanResponsePagedResponse } from "../common/models/creatorDashboard/fanResponse";

export const linkPreviewApi = createApi({
  reducerPath: "linkPreviewApi",
  baseQuery: fetchBaseQuery({
    baseUrl: EXPRESS_BASE_URL,
  }),
  endpoints: (builder) => ({
    getLinkPreviews: builder.mutation<ILinkPreviewData, { clientId: string; url: string }>({
      query: ({ clientId, url }) => ({
        url: `link_preview?clientId=${clientId}`,
        body: { url },
        method: "POST",
      }),
    }),
  }),
});

export const { useGetLinkPreviewsMutation } = linkPreviewApi;
