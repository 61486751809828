import hash from "object-hash";

export const POLLING_END_STRING = "__END__";

export interface IChatResponder {
  id: string;
  name: string;
  description?: string;
  socialHandle: string;
  avatarUrl: string;
  socialUrl: string;
  initialMessage: string;
}

export enum IChatMessageType {
  User = "user",
  Bot = "bot",
}

export interface IChatMessageWithResponderModel {
  type: IChatMessageType;
  text: string;
  model?: string;
}

export interface IChatResponderRequest {
  fanName: string;
  messages: IChatMessageWithResponderModel[];
}

export interface IChatResponderResponse {
  reply: string;
}

export type IChatResponderModel = (
  request_hash: string,
  request: IChatResponderRequest,
  responder_id: string
) => Promise<IChatResponderResponse>;

export interface IFeedbackRequest {
  name: string;
  email: string;
  message: string;
}

export const createCacheKey = (responderId: string, fanName: string, messages: IChatMessageWithResponderModel[]) => {
  const request = {
    fanName: fanName,
    messages: messages,
  };
  return hash({
    responderId: responderId,
    request: request,
  });
}