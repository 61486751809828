import React, { useEffect, useRef } from "react";
import { View, Text, Modal, StyleSheet, Dimensions, TouchableOpacity, ScrollView } from "react-native";
import { useIsMobileWeb } from "../hooks";

interface TermsOfServiceModalProps {
  visible: boolean;
  onClose: () => void;
}

const TOS_TEXT = `The services are provided "as is," "with all faults," and "as available." You bear the entire risk as to its quality, safety, comfort, and performance. Except to the extent prohibited by law, we and our affiliates and licensors make no warranties (express, implied, statutory, or otherwise) with respect to the services, disclaim all warranties including but not limited to warranties of merchantability, fitness for a particular purpose, satisfactory quality, non-infringement, and quiet enjoyment, and any warranties arising out of any course of dealing or trade usage. We do not warrant that the services will be uninterrupted, accurate, or error free, or that any content will be secure, not lost, or unaltered.

The content provided by the services has been algorithmically generated by an artificial intelligence language model. The services may sometimes provide inaccurate or offensive content that does not represent Prof G Media’s views. You are solely responsible for using the content generated by the services in any way. Do not rely on the services for medical, legal, financial, or other professional advice. The contents provided by the services are for informational purposes only.`

const TermsOfServiceModal: React.FC<TermsOfServiceModalProps> = ({ visible, onClose }) => {
  const screenSize = Dimensions.get('window');
  const isMobileWeb = useIsMobileWeb();

  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={visible}
      onRequestClose={onClose}
    >
      <View style={{backgroundColor: "rgba(0,0,0,0.5)", flex: 1}} >
        <View style={[styles.centeredView, {marginHorizontal: isMobileWeb ? 0 : screenSize.width * 0.15}]}>
            <View style={styles.modalView}>
              <Text style={styles.modalTitle}>Terms of Use</Text>
              <ScrollView style={{ maxHeight: screenSize.height * 0.7 }}>
                <Text style={styles.modalText}>{TOS_TEXT}</Text>
              </ScrollView>
              <TouchableOpacity style={styles.button} onPress={onClose}>
                <Text style={styles.buttonText}>Close</Text>
              </TouchableOpacity>
            </View>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  modalView: {
    margin: 40,
    backgroundColor: "white",
    borderRadius: 20,
    paddingVertical: 40,
    paddingHorizontal: 40,
    alignItems: "flex-start",
  },
  modalTitle: {
    textAlign: "left",
    fontFamily: "InterstateCondensedBold",
    fontSize: 32,
    marginBottom: 20,
  },
  modalText: {
    marginBottom: 20,
    textAlign: "left",
    fontFamily: "Roboto-Regular",
    fontSize: 18,
    lineHeight: 29,
  },
  button: {
    backgroundColor: "#4FAFB8",
    padding: 10,
    margin: 20,
    elevation: 2,
    borderRadius: 10,
    alignSelf: "center",
    outlineStyle: "none",
  },
  buttonText: {
    color: "white",
  },
});

export default TermsOfServiceModal;
