import React, { useState } from 'react';
import { Text, View, StyleSheet, ViewStyle } from 'react-native';
import { createElement } from "react-native-web";

export enum LinkType {
  Books = 'Books',
  Podcasts = 'Podcasts',
  Speaking = 'Speaking'
}

interface NavLinkProps {
    onLinkClick: (link: LinkType) => void;
}

const NavLinks: React.FC<NavLinkProps> = ({ onLinkClick }) => {
    const [hoveredLink, setHoveredLink] = useState<LinkType | null>(null);

    const handleMouseEnter = (link: LinkType) => setHoveredLink(link);
    const handleMouseLeave = () => setHoveredLink(null);
    
    const renderLink = (link: LinkType) => (
      createElement('div', {
        onMouseEnter: () => handleMouseEnter(link),
        onMouseLeave: handleMouseLeave,
        children: (
          <Text 
            style={[styles.link, hoveredLink === link && styles.hoveredLink]}
            onPress={() => onLinkClick(link)}
          >
            {link}
          </Text>
        )
      })
  );

    return (
        <View style={styles.container}>
            {renderLink(LinkType.Books)}
            <Text style={styles.divider}>|</Text>
            {renderLink(LinkType.Podcasts)}
            <Text style={styles.divider}>|</Text>
            {renderLink(LinkType.Speaking)}
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
      flexDirection: 'row',
      alignItems: 'center'
    },
    link: {
      fontFamily: "InterstateCondensedRegular",
      fontSize: 22,
    },
    hoveredLink: {
      fontWeight: 'bold'
    },
    divider: {
      padding: 10
    }
});

export default NavLinks;
