import React from "react";
import {
  Composer,
  ComposerProps,
  IMessage,
  SendProps,
} from "react-native-gifted-chat-forked";
import { Platform } from "react-native";
import { useIsMobileWeb } from "../../hooks";
import { useTheme } from "react-native-paper";
import { useAppSelector } from "../../hooks";
import { useLogMutation } from "../../api/logging";

export const ChatComposer = (
  props: ComposerProps & {
    // GiftedChat passes its props to all of its `render*()`
    onSend: SendProps<IMessage>["onSend"];
    text: SendProps<IMessage>["text"];
  }
) => {
  const theme = useTheme();
  const isMobileWeb = useIsMobileWeb();
  // const [composerHeight, setComposerHeight] = React.useState(0);

  const clientId = useAppSelector((state) => state.user.clientId);
  const [log] = useLogMutation();

  return (
    <Composer
      {...props}
      multiline
      textInputAutoFocus
      placeholder="Ask Scott..."
      textInputStyle={{
        textAlignVertical: "top",
        borderRadius: 20,
        color: theme.colors.onBackground,
        fontSize: isMobileWeb ? 14 : 16,
        lineHeight: isMobileWeb ? 22 : 28,
        margin: 0,
        paddingVertical: 13, 
        paddingHorizontal: 19,
        minHeight: isMobileWeb ? 48 : 54,
        overflow: "hidden"
      }}
      textInputProps={{
        // for enabling the Return key to send a message only on web
        autoFocus: true,
        multiline: true,
        blurOnSubmit: false,
        maxLength: 1000,
        // onContentSizeChange: (e) => {
        //   const { width, height } = e.nativeEvent.contentSize;
        //   console.log("content size change: ", width, height);
        //   setComposerHeight(height);
        // },
        onKeyPress:
          Platform.OS === "web"
            ? (e) => {
                if (
                  !props.text &&
                  e.nativeEvent instanceof KeyboardEvent &&-
                  !e.nativeEvent.metaKey
                ) {
                  log({
                    clientId,
                    data: {
                      client_event: "chat_started_typing",
                    },
                  });
                }
                if (
                  !isMobileWeb &&
                  e.nativeEvent instanceof KeyboardEvent &&
                  e.nativeEvent.key === "Enter" &&
                  !e.nativeEvent.shiftKey
                ) {
                  if (props.text && props.onSend) {
                    props.onSend({ text: props.text.trim() }, true);
                  }
                  e.preventDefault();
                }
              }
            : undefined,
      }}
      // composerHeight={composerHeight}
      // onInputSizeChanged={(layout: { width: number, height: number }) => {
      //   console.log("layout height: ", layout.height);
      //   // setComposerHeight(layout.height);
      // }}
    />
  );
};
