import React from 'react';
import { View, StyleSheet, Linking, Image, TouchableOpacity } from 'react-native';
import { IconButton } from 'react-native-paper';
import { useIsMobileWeb } from '../../hooks';

const LinkOutHeaders = () => {
  const isMobileWeb = useIsMobileWeb();
  const largerIconSize = isMobileWeb ? 34 : 46;
  const smallerIconSize = isMobileWeb ? 18 : 30;

  const styles = StyleSheet.create({
    buttonRow: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: isMobileWeb ? 0 : 10,
    },
    imgButton: {
      width: largerIconSize,
      height: largerIconSize,
      filter: 'none'
    }, 
    iconButton: {
      margin: isMobileWeb ? 4 : 6
    }
  });

  return (
    <View style={styles.buttonRow}>
      <IconButton
        icon="web" 
        mode="outlined"
        size={smallerIconSize}
        style={styles.iconButton}
        onPress={() => Linking.openURL("https://profgmedia.com/")}
      />
      <IconButton
        icon={ require('../../../assets/icon-podcast.png') }
        size={largerIconSize}
        style={[styles.imgButton, styles.iconButton]}
        onPress={() => Linking.openURL("https://link.chtbl.com/ProfGPod?sid=web")}
      />
      <IconButton 
        icon={ require('../../../assets/icon-tiktok.png') }
        size={largerIconSize}
        style={[styles.imgButton, styles.iconButton]}
        onPress={() => Linking.openURL("https://www.tiktok.com/@profgalloway") }
      /> 
      <IconButton
        icon="youtube"
        mode="outlined"
        size={smallerIconSize}
        style={styles.iconButton}
        onPress={() => Linking.openURL("https://www.youtube.com/@TheProfGShow")}
      />
      <IconButton
        icon={ require('../../../assets/icon-x.png') }
        size={largerIconSize}
        style={[styles.imgButton, styles.iconButton]}
        onPress={() => Linking.openURL("https://twitter.com/profgalloway")}
      />
      <IconButton
        icon={ require('../../../assets/icon-post.png') }
        size={largerIconSize}
        style={[styles.imgButton, styles.iconButton]}
        onPress={() => Linking.openURL("https://post.news/@/ProfG")}
      />
      <IconButton
        icon="instagram"
        mode="outlined"
        size={smallerIconSize}
        style={styles.iconButton}
        onPress={() => Linking.openURL("https://www.instagram.com/profgalloway/")}
      />
    </View>
  );
};

export default LinkOutHeaders;
