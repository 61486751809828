import React from "react";
import { createDrawerNavigator } from "@react-navigation/drawer";
import ChatScreen from "../screens/ChatScreen";
import AboutScreen from "../screens/AboutScreen";
import LandingScreen from "../screens/LandingScreen";
import CreatorDashboardScreen from "../screens/CreatorDashboardScreen";
import { useAppSelector, useIsMobileWeb } from "../hooks";
import { IconButton } from "react-native-paper";
import { StyleSheet, useWindowDimensions, ScrollView, View } from "react-native";
import { createStackNavigator } from "@react-navigation/stack";
import { useLogMutation } from "../api/logging";
import { 
  MainDrawerNavigatorParamList, 
  MainStackNavigatorParamList 
} from "./navigationTypes";

const StackNavigator = createStackNavigator<MainStackNavigatorParamList>();
const DrawerNavigator = createDrawerNavigator<MainDrawerNavigatorParamList>();

const MainStack = () => {
  return (
    <StackNavigator.Navigator initialRouteName="Landing">
      <StackNavigator.Screen name="Landing" component={LandingScreen} options={{ headerShown: false }} />
      <StackNavigator.Screen name="Main" component={MainDrawerNavigator} options={{ headerShown: false }} />
      <StackNavigator.Screen name="CreatorDashboard" component={CreatorDashboardScreen} />
    </StackNavigator.Navigator>
  );
};

const MainDrawerNavigator = () => {
  const dimensions = useWindowDimensions();
  const isMobileWeb = useIsMobileWeb();
  const creatorsList = useAppSelector((state) => state.creators.creatorModels);

  const clientId = useAppSelector((state) => state.user.clientId);
  const [log] = useLogMutation();

  return isMobileWeb ? (
    <StackNavigator.Navigator>
      <StackNavigator.Screen
        name="Chat"
        component={ChatScreen}
        initialParams={{
          creatorId: creatorsList.length > 0 ? creatorsList[0].id : "",
        }}
      />
    </StackNavigator.Navigator>
  ) : (
    <DrawerNavigator.Navigator
      initialRouteName="About"
      useLegacyImplementation={true}
      screenOptions={() => ({
        drawerType: isMobileWeb ? "front" : "permanent",
        drawerStyle: {
          width: isMobileWeb ? 0 : 384,
          borderRightWidth: 0,
        },
      })}
      drawerContent={(props) => {
        return (
          <View style={styles.aboutScreenShadow}>
            <AboutScreen onExplorePress={() => {}} />
          </View>
        );
      }}
    >
      <DrawerNavigator.Screen
        name="Chat"
        component={ChatScreen}
        initialParams={{
          creatorId: creatorsList.length > 0 ? creatorsList[0].id : "",
        }}
      />
    </DrawerNavigator.Navigator>
  );
};

const styles = StyleSheet.create({
  drawerContent: {
    flex: 1,
    padding: 20,
    justifyContent: 'center',
    alignItems: 'flex-start', // Align text to the left
  },
  aboutScreenShadow: {
    flex: 1,
    shadowColor: "#000",  // shadow color
    shadowOffset: {
      width: 6,
      height: 0,
    },
    shadowOpacity: 0.25,
    shadowRadius: 20,
    elevation: 5,  // for Android
  },
});

export default MainStack;
