import React, { useEffect, useLayoutEffect } from "react";
import { Linking, StyleSheet, View, Image, ScrollView, Dimensions } from "react-native";
import { Button, Text, useTheme, IconButton } from "react-native-paper";
import { Link, useNavigation } from "@react-navigation/native";
import { useAppSelector, useIsMobileWeb } from "../hooks";
import { useLogMutation } from "../api/logging";
import { AboutScreenNavigationProp } from "../navigation/navigationTypes";
import NavLinks, { LinkType } from "../components/NavLinks";

const AboutScreen = ({ onExplorePress }:
{ onExplorePress: () => void }) => {
  const clientId = useAppSelector((state) => state.user.clientId);
  const navigation = useNavigation<AboutScreenNavigationProp>();
  const theme = useTheme();
  const [log] = useLogMutation();
  const isMobileWeb = useIsMobileWeb();

  useEffect(() => {
    log({
      clientId,
      data: {
        client_event: "about_profg_screen_opened",
      },
    });
  }, []);

  const styles = StyleSheet.create({
    container: {
      flexDirection: "column",
      flex: 1,
      justifyContent: "space-between",
      alignItems: "flex-start",
      alignContent: "flex-start",
      paddingHorizontal: isMobileWeb ? 30 : 60,
    },
    closeButtonContainer: {
      width: '100%',
      alignItems: 'flex-end',
      padding: 20,
      backgroundColor: theme.colors.primary,
    },
    imageStyle: {
      width: 145,
      height: 110,
      resizeMode: 'contain',
      marginTop: isMobileWeb ? 0 : 60,
    },
    title: {
      textAlign: "left",
      fontSize: 65,
      fontFamily: "InterstateCondensedBold",
      marginTop: 20,
    },
    headerDescription: {
      textAlign: "left",
      fontFamily: "InterstateCondensedBold",
      fontSize: 28,
      marginTop: 20,
      marginBottom: 30,
    },
    listContainer: {
      marginTop: 60,
      textAlign: "left",
    },
    bulletItem: {
      marginBottom: 10,
      fontFamily: "InterstateCondensedRegular",
      fontSize: 20,
    },
    boldText: {
      fontFamily: "InterstateCondensedBold",
      fontSize: 20,
    },
    hyperlink: {
      fontFamily: "InterstateCondensedBold",
      fontSize: 20,
      textDecorationLine: "underline",
    },
    italicText: {
      fontStyle: "italic",
      fontFamily: "InterstateCondensedRegular",
    },
    bottomContainer: {
      alignItems: 'center',
      paddingBottom: 70,
      width: "100%",
    },
    footerDescriptionText: {
      textAlign: "center",
      fontSize: 22,
      fontFamily: "InterstateCondensedBold",
    },
    button: {
      marginTop: 30,
    },
    spacer: {
      flex: 1,
      minHeight: 100,
    },
  });

  const handleLinkClick = (linkType: LinkType) => {
    switch (linkType) {
      case LinkType.Books:
        Linking.openURL("https://profgmedia.com/books/");
        break;
      case LinkType.Podcasts:
        Linking.openURL("https://profgmedia.com/the-pod/");
        break;
      case LinkType.Speaking:
        Linking.openURL("https://profgmedia.com/speaking/");
        break;
      default:
        console.error("Unexpected link type clicked on about screen: ${linkType}")
    }
  };

  const windowHeight = Dimensions.get('window').height;
  const minHeight = isMobileWeb ? windowHeight - 88 : windowHeight;

  return (
    <>
      {isMobileWeb && (
      <View style={styles.closeButtonContainer}>
        <IconButton
          icon="close"
          size={20}
          onPress={() => onExplorePress()}
        />
      </View>
      )}
      <ScrollView 
        showsVerticalScrollIndicator={false}
        style={{ maxHeight: windowHeight, overscrollBehaviorY: "none" }}
        bounces={false}>
        <View style={[styles.container, {backgroundColor: theme.colors.primary, minHeight: minHeight}]}>
          <Image 
          source={require('../../assets/scott-galloway-atom.png')} 
          style={styles.imageStyle}
          />
          <View>
            <Text style={[styles.title, {color: theme.colors.onPrimary}]}>
              ProfG.AI
            </Text>
          </View>
          <View>
            <Text style={styles.headerDescription}>
              Scott Galloway: entrepreneur, professor, board member, author, father.
              Digitally distilled. 24/7.
            </Text>
          </View>
          <NavLinks onLinkClick={handleLinkClick} />
          <View style={styles.spacer} />
          <View style={styles.bottomContainer}>
            <Text style={styles.footerDescriptionText}>
              Join 400K+ No Mercy / No Malice newsletter subscribers
            </Text>
            <Button
              style={styles.button}
              mode="outlined"
              onPress={() => Linking.openURL("https://www.profgalloway.com/subscribe/")}
              theme={{ colors: { primary: "black", outline: "black" } }}
              labelStyle={{fontFamily: "Roboto-Regular", fontWeight: "600", fontSize: 16, paddingHorizontal: 10}}
            >
              Subscribe
          </Button>
          </View>
        </View>
      </ScrollView>
    </>
  );
};

export default AboutScreen;
