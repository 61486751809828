import React from "react";
import { StyleSheet, View, Image } from "react-native";
import { TypingAnimation } from "react-native-typing-animation";
import { GiftedAvatar, User } from "react-native-gifted-chat-forked";
import { useTheme } from "react-native-paper";
import { useSpring, animated } from '@react-spring/web'

interface Props {
  isTyping: boolean;
  user?: User;
}

const TypingIndicator = ({ isTyping, user }: Props) => {
  const theme = useTheme();
  const silhouetteHead = require('../../../assets/silhouette-head.png');

  const firstHeadAnim = useSpring({
    from: { top: 0 },
    to: [{ top: 10 }, { top: 0 }],
    loop: true,
    reset: true,
    config: { 
      duration: 600
    }
  });

  const secondHeadAnim = useSpring({
    from: { top: 5 },
    to: [{ top: 10 }, { top: 0 }, { top: 5 }],
    loop: true,
    reset: true,
    config: { 
      duration: 300
    }
  });

  const thirdHeadAnim = useSpring({
    from: { top: 10 },
    to: [{ top: 0 }, { top: 10 }],
    loop: true,
    reset: true,
    config: { 
      duration: 600
    }
  });

  return (
    <View style={styles.container}>
      {isTyping ? (
        <View style={styles.hContainer}>
          <animated.div style={styles.silhouetteHeadContainer}>
            <animated.img src={silhouetteHead} style={{
              rotate: -3,
              ...styles.silhouetteHead,
              ...firstHeadAnim 
            }} />
          </animated.div>
          <animated.div style={styles.silhouetteHeadContainer}>
            <animated.img src={silhouetteHead} style={{
              ...styles.silhouetteHead,
              ...secondHeadAnim 
            }} />
          </animated.div>
          <animated.div style={styles.silhouetteHeadContainer}>
            <animated.img src={silhouetteHead} style={{
              rotate: 7,
              ...styles.silhouetteHead,
              ...thirdHeadAnim 
            }} />
          </animated.div>
        </View>
      ) : null}
    </View>
  );
};

const silhouetteHeadWidth = 12;
const sihouetteHeadHeight = 18;

const styles = StyleSheet.create({
  container: {
    marginLeft: 8,
    marginBottom: 10,
    borderRadius: 15,
    height: 30,
  },
  hContainer: {
    flexDirection: "row",
    paddingHorizontal: 15,
    paddingBottom: 10
  },
  silhouetteHeadContainer: {
    width: silhouetteHeadWidth,
    height: sihouetteHeadHeight,
    marginRight: 8
  },
  silhouetteHead: {
    width: silhouetteHeadWidth,
    height: sihouetteHeadHeight,
    position: 'absolute'
  }
});

export default TypingIndicator;
