import React from 'react';
import { TouchableOpacity, Image } from 'react-native';
import { useIsMobileWeb } from '../../hooks';
import { IMessage, SendProps } from 'react-native-gifted-chat-forked';

const SendButton = <TMessage extends IMessage = IMessage>(props: SendProps<TMessage>) => {
  const { onSend, text } = props;
  const sendIcon = require('../../../assets/ic_round-send.png')
  const isMobileWeb = useIsMobileWeb();

  const buttonStyle = {
    marginBottom: isMobileWeb ? 12 : 15,
    marginRight: 20,
    marginLeft: 10,
  };

  return (
    <TouchableOpacity
      style={buttonStyle}
      onPress={() => {
        onSend && onSend({ text: text?.trim() } as TMessage, true);
      }}
    >
      <Image source={sendIcon} style={{ width: 25, height: 25 }} />
    </TouchableOpacity>
  );
};

export default SendButton;
