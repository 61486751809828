import React from 'react';
import { View, Text, StyleSheet, Linking, TouchableOpacity } from 'react-native';
import { useTheme } from "react-native-paper";
import { useAppSelector } from "../../hooks";
import { useLogMutation } from "../../api/logging";

const ServerErrorNotification = () => {
  const theme = useTheme();
  const clientId = useAppSelector((state) => state.user.clientId);
  const [log] = useLogMutation();

  return (
    <View style={styles.container}>
        <Text style={styles.errorText}>
          {"There was a server error, try again later."}
        </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 10,
    paddingHorizontal: 100,
  },
  errorText: {
    color: "grey",
    textAlign: "center",
  },
});

export default ServerErrorNotification;