import { DefaultTheme } from "react-native-paper";

export const lightTheme = {
  ...DefaultTheme,
  roundness: 10,
  colors: {
    primary: "rgb(79, 175, 184)",
    onPrimary: "rgb(255, 255, 255)",
    primaryContainer: "rgb(255, 225, 110)",
    onPrimaryContainer: "rgb(34, 27, 0)",
    secondary: "rgb(0, 104, 116)",
    onSecondary: "rgb(255, 255, 255)",
    secondaryContainer: "rgb(249, 249, 249)",
    onSecondaryContainer: "rgb(0, 31, 36)",
    tertiary: "rgb(56, 107, 1)",
    onTertiary: "rgb(255, 255, 255)",
    tertiaryContainer: "rgb(183, 244, 129)",
    onTertiaryContainer: "rgb(13, 32, 0)",
    error: "rgb(186, 26, 26)",
    onError: "rgb(255, 255, 255)",
    errorContainer: "rgb(255, 218, 214)",
    onErrorContainer: "rgb(65, 0, 2)",
    background: "rgb(255, 251, 255)",
    onBackground: "rgb(29, 27, 22)",
    surface: "rgb(255, 251, 255)",
    onSurface: "rgb(29, 27, 22)",
    surfaceVariant: "rgb(234, 226, 207)",
    onSurfaceVariant: "rgb(0, 0, 0)",
    outline: "rgb(0, 0, 0)",
    outlineVariant: "rgb(205, 198, 180)",
    shadow: "rgb(0, 0, 0)",
    scrim: "rgb(0, 0, 0)",
    inverseSurface: "rgb(51, 48, 42)",
    inverseOnSurface: "rgb(246, 240, 231)",
    inversePrimary: "rgb(232, 196, 29)",
    elevation: {
      level0: "transparent",
      level1: "rgb(248, 243, 242)",
      level2: "rgb(79, 175, 184)",
      level3: "rgb(239, 234, 227)",
      level4: "rgb(238, 232, 224)",
      level5: "rgb(235, 229, 219)",
    },
    surfaceDisabled: "rgba(29, 27, 22, 0.12)",
    onSurfaceDisabled: "rgba(29, 27, 22, 0.38)",
    backdrop: "rgba(52, 48, 36, 0.4)",
  },
};

export const darkTheme = {
  ...DefaultTheme,
  roundness: 10,
  colors: {
    primary: "rgb(232, 196, 29)",
    onPrimary: "rgb(58, 48, 0)",
    primaryContainer: "rgb(84, 70, 0)",
    onPrimaryContainer: "rgb(255, 225, 110)",
    secondary: "rgb(79, 216, 235)",
    onSecondary: "rgb(0, 54, 61)",
    secondaryContainer: "rgb(0, 79, 88)",
    onSecondaryContainer: "rgb(151, 240, 255)",
    tertiary: "rgb(156, 215, 105)",
    onTertiary: "rgb(26, 55, 0)",
    tertiaryContainer: "rgb(40, 80, 0)",
    onTertiaryContainer: "rgb(183, 244, 129)",
    error: "rgb(255, 180, 171)",
    onError: "rgb(105, 0, 5)",
    errorContainer: "rgb(147, 0, 10)",
    onErrorContainer: "rgb(255, 180, 171)",
    background: "rgb(29, 27, 22)",
    onBackground: "rgb(232, 226, 217)",
    surface: "rgb(29, 27, 22)",
    onSurface: "rgb(232, 226, 217)",
    surfaceVariant: "rgb(75, 71, 57)",
    onSurfaceVariant: "rgb(205, 198, 180)",
    outline: "rgb(151, 144, 128)",
    outlineVariant: "rgb(75, 71, 57)",
    shadow: "rgb(0, 0, 0)",
    scrim: "rgb(0, 0, 0)",
    inverseSurface: "rgb(232, 226, 217)",
    inverseOnSurface: "rgb(51, 48, 42)",
    inversePrimary: "rgb(112, 93, 0)",
    elevation: {
      level0: "transparent",
      level1: "rgb(39, 35, 22)",
      level2: "rgb(45, 41, 23)",
      level3: "rgb(51, 46, 23)",
      level4: "rgb(53, 47, 23)",
      level5: "rgb(57, 51, 23)",
    },
    surfaceDisabled: "rgba(232, 226, 217, 0.12)",
    onSurfaceDisabled: "rgba(232, 226, 217, 0.38)",
    backdrop: "rgba(52, 48, 36, 0.4)",
  },
};
