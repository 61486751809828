import { useState } from "react";
import { Provider as ReduxProvider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import * as Linking from "expo-linking";
import { store, persistor } from "./src/store";
import { SafeAreaProvider } from "react-native-safe-area-context";
import Startup from "./src/components/Startup";
import {
  Provider as PaperProvider,
  adaptNavigationTheme,
} from "react-native-paper";
import { darkTheme, lightTheme } from "./src/theme";
import {
  DefaultTheme as NavigationDefaultTheme,
  DarkTheme as NavigationDarkTheme,
} from "@react-navigation/native";

import React from "react";
import { NavigationContainer } from "@react-navigation/native";
import RootNavigator from "./src/navigation/root";
import { Appearance } from "react-native";
import ReactGA from "react-ga4";
import * as Font from "expo-font";
import AppLoading from 'expo-app-loading';
import { InitialTextProvider } from "./src/context/InitialTextContext";

const linking = {
  prefixes: [Linking.createURL("/")],
  config: {
    screens: {
      Landing: "",
      Main: {
        path: "main",
        screens: {
          About: "",
          Chat: "chat/:creatorId",
        }
      },
      CreatorDashboard: "creatordashboard"
    }
  }
};


const { LightTheme: navLightTheme, DarkTheme: navDarkTheme } =
  adaptNavigationTheme({
    reactNavigationLight: NavigationDefaultTheme,
    reactNavigationDark: NavigationDarkTheme,
    materialLight: lightTheme,
    materialDark: darkTheme,
  });

  if (process.env.GA_MEASUREMENT_ID) {
    ReactGA.initialize(process.env.GA_MEASUREMENT_ID);
} else {
    console.error('GA_MEASUREMENT_ID is not defined in the environment variables.');
}

const fetchFonts = () => {
  return Font.loadAsync({
    'InterstateCondensedRegular': require('./assets/fonts/interstate-regular.ttf'),
    'InterstateCondensedBold': require('./assets/fonts/interstate-bold.ttf'),
    'Roboto-Regular': require('./assets/fonts/Roboto-Regular.ttf'),
  });
};

export default function App() {
  const [fontLoaded, setFontLoaded] = useState(false);
  const colorScheme = Appearance.getColorScheme();
  if (!fontLoaded) {
    return (
      <AppLoading
        startAsync={fetchFonts}
        onFinish={() => setFontLoaded(true)}
        onError={(err) => console.log(err)}
      />
    );
  }

  return (
    <ReduxProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <PaperProvider theme={lightTheme}>
          <SafeAreaProvider>
            <Startup>
              <InitialTextProvider>
                <NavigationContainer
                  linking={linking}
                  theme={navLightTheme}
                >
                  <RootNavigator />
                </NavigationContainer>
              </InitialTextProvider>
            </Startup>
          </SafeAreaProvider>
        </PaperProvider>
      </PersistGate>
    </ReduxProvider>
  );
}
