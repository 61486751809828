import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { EXPRESS_BASE_URL } from "./config";
import { IFeedbackRequest } from "../common/models/responders";

export const feedbackApi = createApi({
  reducerPath: "feedbackApi",
  baseQuery: fetchBaseQuery({
    baseUrl: EXPRESS_BASE_URL,
  }),
  endpoints: (builder) => ({
    submitFeedback: builder.mutation<
      object,
      { clientId: string; feedback: IFeedbackRequest }
    >({
      query: ({ clientId, feedback }) => {
        return {
          url: `feedback?clientId=${clientId}`,
          body: feedback,
          method: "POST",
        };
      },
    }),
  }),
});

export const { useSubmitFeedbackMutation } = feedbackApi;
